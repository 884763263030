//setting
@import "../common/css/_mq.scss";
@import "../common/css/_var.scss";
@import "../common/css/_mixin.scss";

.notFound{
  width: 570px;
  margin: 130px auto 260px;

  @include sp{
    width: 100%;
    margin: 50px auto 110px;
    padding: 0 5.3%;
  }

  & > p:first-child{
    margin-bottom: 80px;

    @include sp{
      text-align: left;
      margin-bottom: 50px;
    }
  }

  & > p{
    text-align: center;
  }
}